import { Trans } from '@lingui/macro';
import { useAuthContext, useLanguageContext } from 'noddi-provider';
import { NoddiButton, NoddiIconButton, NoddiLanguageSelector } from 'noddi-ui';
import { useLocation, useNavigate } from 'react-router-dom';

import { getSupportedLocales } from '../../App';
import routes from '../../appRoutes';
import { useBackNavigation } from '../../pages/BookingFlow/hooks/useBackNavigation';
import ImpersonatingAlert from '../ImpersonatingAlert';

export const CustomerBookingHeaderNav = () => {
  const { isLoggedIn, impersonatedUser } = useAuthContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Dont use contains, as we want backbutton in login page in booking flow
  const isLoginPage = pathname === routes.login.getPath();
  const { currentLanguage, setCurrentLanguage } = useLanguageContext();
  const isLoginInBookingFlowPage = pathname.includes(routes.newBookingLoginStep.getPath());
  const isAddressStep = pathname.includes(routes.newBookingAddressStep.getBasePath());

  const { goBack } = useBackNavigation();

  const goToLogin = () => {
    const redirectUrl = window.location.pathname !== routes.login.getPath() ? window.location.pathname : undefined;
    const extraParams = redirectUrl ? `?redirectUrl=${redirectUrl}` : '';
    navigate(`${routes.login.getPath()}${extraParams}`);
  };

  const goToNewBooking = () => {
    navigate(routes.newBookingAddressStep.getPath());
  };

  const CreateBookingButton = () => (
    <NoddiButton variant='ghost' onClick={goToNewBooking} endIcon='ArrowRight'>
      <Trans>Create booking</Trans>
    </NoddiButton>
  );

  const MyProfileButton = () => (
    <NoddiButton variant='ghost' onClick={() => navigate('https://noddi.no')} endIcon='UserCircle'>
      <Trans>My profile</Trans>
    </NoddiButton>
  );

  const SignInButton = () => (
    <NoddiButton variant='ghost' onClick={goToLogin} endIcon='UserCircle'>
      <Trans>Sign in</Trans>
    </NoddiButton>
  );

  const showBackButton = !isLoginPage && !isAddressStep;

  return (
    <>
      {!!impersonatedUser && <ImpersonatingAlert />}
      <div className='relative top-4 mx-auto mb-4 max-w-screen-lg rounded-full bg-primary-white/60 px-10 py-4 max-lg:mx-4 max-sm:px-5'>
        <div>
          <div className='flex w-full items-center gap-2'>
            {showBackButton && (
              <NoddiIconButton iconName='ArrowLeft' iconSize='medium' onClick={goBack} variant='secondary' />
            )}
            <NoddiLanguageSelector
              supportedLanguages={getSupportedLocales()}
              currentLanguage={currentLanguage}
              setCurrentLanguage={setCurrentLanguage}
            />

            {!isLoginInBookingFlowPage && (
              <div className='ml-auto flex gap-3 '>
                {isLoginPage ? <CreateBookingButton /> : <>{isLoggedIn ? <MyProfileButton /> : <SignInButton />}</>}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
