import { Route, Routes } from 'react-router-dom';

import routes from '../appRoutes';
import Addons from './BookingFlow/Steps/Addons';
import Address from './BookingFlow/Steps/Address';
import Car from './BookingFlow/Steps/Cars';
import { LoginBookingFlow } from './BookingFlow/Steps/Login';
import SalesItemsSelector from './BookingFlow/Steps/SalesItems';
import Summary from './BookingFlow/Steps/Summary';
import TimeWindows from './BookingFlow/Steps/TimeWindows';
import WheelStoragePage from './BookingFlow/Steps/WheelStorage';

export function BookingRouter() {
  return (
    <Routes>
      {/* Make sure that root goes to address page for backwards compatibility */}
      <Route path='/' element={<Address />} />
      <Route path={routes.newBookingAddressStep.getBasePath()} element={<Address />} />
      <Route path={routes.newBookingCarStep.getBasePath()} element={<Car />} />
      <Route path={routes.newBookingServicesStep.getBasePath()} element={<SalesItemsSelector />} />
      <Route path={routes.newBookingAddonsStep.getBasePath()} element={<Addons />} />
      <Route path={routes.newBookingWheelStorageStep.getBasePath()} element={<WheelStoragePage />} />
      <Route path={routes.newBookingTimeSlotStep.getBasePath()} element={<TimeWindows />} />
      <Route path={routes.newBookingSummaryStep.getBasePath()} element={<Summary />} />
      <Route path={routes.newBookingLoginStep.getBasePath()} element={<LoginBookingFlow />} />
    </Routes>
  );
}
