import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { UserData, UserDataProps } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { trimAllWhiteCharacters } from 'noddi-util';
import { useState } from 'react';

import { cn } from '../../../helpers/utilts';
import { NoddiFeedbackBox } from '../../../molecules';
import { PhoneNumberForm } from './PhoneNumberForm';
import { VerificationCodeForm } from './VerificationCodeForm';

const userAlreadyExists = (data: UserDataProps) => {
  const token = data.token;
  return token && token.length > 0;
};

export type CallBackObject = {
  customCallback: () => void;
  title: string;
};

type Props = {
  userRegistrationForm: (phoneNumber: string) => React.ReactElement;
  callBackObject?: CallBackObject;
  className?: string;
  hidePhoneNumberText?: boolean;
  trackUserIdentity?: (userData: UserData) => void;
};

// Since the worker app and the customer app are using the same codebase, we need to
// allow for different user registration forms. This is why provide a callback function
const SMSLogin = ({
  userRegistrationForm,
  callBackObject,
  className,
  hidePhoneNumberText,
  trackUserIdentity
}: Props) => {
  const { loginUser, isLoggedIn } = useAuthContext();
  const [mobileNumber, setMobileNumber] = useState('');
  const [codeSentSuccessfully, setCodeSentSuccessfully] = useState(false);

  const {
    mutateAsync,
    isPending,
    isSuccess: verificationSuccess,
    data,
    error
  } = noddiAsync.usePost({
    type: URLKeys.postVerifyPhoneNumber,
    queryConfig: {
      onSuccess(data) {
        if (userAlreadyExists(data.data)) {
          trackUserIdentity?.(data.data.user);
          loginUser(data.data);
        }
      }
    }
  });

  const phoneNumberDto = ({ urlEncode = false }: { urlEncode?: boolean }) => {
    const serializedMobileNumber = trimAllWhiteCharacters(mobileNumber);

    return urlEncode ? encodeURIComponent(serializedMobileNumber) : serializedMobileNumber;
  };

  if (isLoggedIn) {
    return <NoddiFeedbackBox variant='warning' heading={t`Already logged in`}></NoddiFeedbackBox>;
  }

  return (
    <div className={cn('flex flex-col gap-10', className)}>
      <PhoneNumberForm
        setCodeSentSuccessfully={setCodeSentSuccessfully}
        mobileNumber={mobileNumber}
        setMobileNumber={setMobileNumber}
        callBackObject={callBackObject}
        hideText={hidePhoneNumberText}
      />
      {codeSentSuccessfully && (
        <VerificationCodeForm
          error={error}
          isLoading={isPending}
          phoneNumber={phoneNumberDto({})}
          verificationSuccess={verificationSuccess}
          onClick={({ code, phoneNumber }: { code: string; phoneNumber: string }) => {
            mutateAsync({ code, phoneNumber });
          }}
        />
      )}
      {verificationSuccess && !userAlreadyExists(data.data) && userRegistrationForm(phoneNumberDto({}))}
    </div>
  );
};

export { SMSLogin };
