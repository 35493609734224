import { Trans, t } from '@lingui/macro';
import { useAuthContext } from 'noddi-provider';
import {
  LoadingScreen,
  NoddiIcon,
  NoddiIconButton,
  NoddiProgressBar,
  SMSLogin,
  StickyBottomBarWrapper,
  UserRegistrationForm,
  getLongAddressName
} from 'noddi-ui';
import { useEffect } from 'react';

import { useBookingContext } from '../../../../contexts/BookingContext';
import bookingTimeWindowToDisplayText from '../../../../helper/timeWindow';
import useActivateCoupon from '../../../../hooks/useActivateCoupon';
import tracking from '../../../../tracking';
import BookingScreen, { useIsMobileViewInBookingFlow } from '../../BookingScreen';
import { useBackNavigation } from '../../hooks/useBackNavigation';
import { useMembershipPrograms } from '../../hooks/useMembershipPrograms';
import CommentBox from './CommentBox';
import ConfirmBooking from './ConfirmBooking';
import PriceSummary from './PriceSummary';

const Summary = () => {
  const { isLoggedIn, isTokenLoginLoading } = useAuthContext();
  const isMobileViewInBookingFlow = useIsMobileViewInBookingFlow();
  const { bookingInputData: inputData, updateBookingInputData, salesItems, bookingInputData } = useBookingContext();
  const { goBack } = useBackNavigation();

  const { activateCouponForSalesItem, couponsFromApi: fetchedCoupons } = useActivateCoupon();

  const { data: membershipData } = useMembershipPrograms();

  const handleCommentChange = (newComment: string) => {
    updateBookingInputData({ comment: newComment });
  };

  useEffect(() => {
    if (isLoggedIn && fetchedCoupons?.length !== 0) {
      salesItems.forEach((salesItem) => {
        activateCouponForSalesItem({ salesItemId: salesItem.id, licensePlateNumber: salesItem.licensePlateNumber });
      });
    }
  }, [isLoggedIn, fetchedCoupons]);

  const timeWindowDisplay =
    (bookingInputData.time && bookingTimeWindowToDisplayText(bookingInputData.time)) ||
    t`Problems with the last time slot`;

  const bookingDataAddress = bookingInputData?.address;
  if (isTokenLoginLoading) {
    return (
      <BookingScreen hideNextButton title={t`Ready to confirm booking?`}>
        <LoadingScreen />
      </BookingScreen>
    );
  }

  return (
    <BookingScreen
      title={t`Ready to confirm booking?`}
      hideNextButton
      CustomBottomBar={
        isLoggedIn ? (
          <StickyBottomBarWrapper>
            <div className='flex w-full justify-between'>
              <div className='flex items-center gap-2'>
                <NoddiIconButton iconName='ArrowLeft' iconSize='medium' onClick={goBack} variant='secondary' />
                <div className='w-full max-w-32'>
                  <NoddiProgressBar value={100} />
                </div>
              </div>
            </div>
            <ConfirmBooking membershipPrograms={membershipData} />
          </StickyBottomBarWrapper>
        ) : null
      }
    >
      <div className='flex flex-col gap-3'>
        <div className='flex flex-col gap-3 rounded-lg bg-primary-white px-4 py-5'>
          <div className=' flex gap-2'>
            <NoddiIcon name='Calendar' size='medium' />
            <p>{timeWindowDisplay}</p>
          </div>
          {bookingDataAddress && (
            <div className='flex gap-2'>
              <NoddiIcon name='LocationPin' size='medium' />
              <p>{getLongAddressName(bookingDataAddress)}</p>
            </div>
          )}
        </div>

        <PriceSummary membershipData={membershipData} />

        <CommentBox comment={inputData.comment} handleChange={handleCommentChange} />

        <div className='flex flex-col gap-3 rounded-lg bg-primary-white px-4 py-5'>
          <p className='text-bold'>
            <Trans>Payment</Trans>
          </p>
          <p>
            <Trans>
              You will receive a link via SMS with payment information after the job has been carried out by our
              employees.
            </Trans>
          </p>
        </div>
      </div>

      {!isLoggedIn && (
        <SMSLogin
          className='mt-4'
          userRegistrationForm={(phoneNumber) => (
            <UserRegistrationForm
              phoneNumber={phoneNumber}
              skipPasswordCreation
              trackUserIdentity={(user) => {
                tracking.trackUserIdentity(user);
              }}
            />
          )}
          callBackObject={isMobileViewInBookingFlow ? undefined : { customCallback: goBack, title: t`Back` }}
        />
      )}
    </BookingScreen>
  );
};

export default Summary;
