import { Trans } from '@lingui/macro';
import { AvailableBookingTimeWindow, MembershipDiscountProps, MembershipProgramProps } from 'noddi-async/src/types';
import { NoddiIcon, colors } from 'noddi-ui';
import { cn } from 'noddi-ui/src/helpers/utilts';
import { formatNorwegianCurrencyAmount } from 'noddi-util';

import { useBookingContext } from '../../../../contexts/BookingContext';
import useBookingSummaryProps from '../../../../hooks/useBookingSummaryProps';
import { ActiveCoupon } from '../../../../stores/CouponStore';
import { SelectedCar, SelectedSalesItem } from '../../interfaces';
import { getIconForSalesItem, getNewPriceForSalesItems } from './utils';

type PriceSummaryProps = {
  membershipData?: MembershipProgramProps[] | undefined;
};

const PriceSummary = ({ membershipData }: PriceSummaryProps) => {
  const { totalPrice, coupons, discounts } = useBookingSummaryProps({ membershipData });

  const { bookingInputData, salesItems } = useBookingContext();

  return (
    <div className='flex flex-col gap-8 rounded-lg bg-primary-white px-4 py-6'>
      <div className='flex flex-col gap-6'>
        {bookingInputData.selectedCars.map((car) => (
          <CarSummary
            key={car.licensePlateNumber}
            car={car}
            salesItems={salesItems.filter((x) => x.licensePlateNumber === car.licensePlateNumber)}
            discounts={discounts}
            coupons={coupons}
          />
        ))}
        <TotalPrice totalPrice={totalPrice} timeWindow={bookingInputData.time} />
      </div>
    </div>
  );
};

const CarSummary = ({
  car,
  salesItems,
  discounts,
  coupons
}: {
  car: SelectedCar;
  salesItems: SelectedSalesItem[];
  discounts: MembershipDiscountProps[];
  coupons: ActiveCoupon[];
}) => {
  const { mainSalesItems, addons } = salesItems.reduce(
    (acc, salesItem) => {
      if (salesItem?.isAddon) {
        acc.addons.push(salesItem);
      } else {
        acc.mainSalesItems.push(salesItem);
      }
      return acc;
    },
    { mainSalesItems: [] as SelectedSalesItem[], addons: [] as SelectedSalesItem[] }
  );

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex content-between items-center gap-2'>
        <NoddiIcon name='Car' size='medium' color={colors.primary.black} className='relative top-1' />
        <h1 className='text-bold'>
          {car.carName} - {car.licensePlateNumber}
        </h1>
      </div>

      {mainSalesItems.map((salesItem) => {
        return (
          <ServiceLine
            name={salesItem.name}
            categorySlug={salesItem?.category?.slug}
            price={salesItem.price}
            id={salesItem.id}
            key={salesItem.id}
            isAddon={false}
            discounts={discounts}
            coupons={coupons}
          />
        );
      })}

      {addons.map((salesItem) => {
        return (
          <ServiceLine
            name={salesItem.name}
            categorySlug={salesItem?.category?.slug}
            price={salesItem.price}
            id={salesItem.id}
            key={salesItem.id}
            isAddon
            discounts={discounts}
            coupons={coupons}
          />
        );
      })}
    </div>
  );
};

const ServiceLine = ({
  name,
  categorySlug,
  price,
  id,
  isAddon,
  discounts,
  coupons
}: {
  name: string;
  categorySlug?: string;
  price: number;
  id: number;
  isAddon: boolean;
  discounts: MembershipDiscountProps[];
  coupons: ActiveCoupon[];
}) => {
  const discountForSalesItem = discounts.find((discount) => discount.salesItemIds.includes(id));
  const couponForSalesItem = coupons.find((coupon) => coupon.validForSalesItemIds.includes(id));

  const newPrice =
    discountForSalesItem || couponForSalesItem
      ? getNewPriceForSalesItems(price, discountForSalesItem, couponForSalesItem)
      : 0;

  const hasTwoPrices = newPrice > 0;

  const getIcon = () => {
    if (isAddon) {
      return <NoddiIcon name='PlusCircle' size='medium' color={colors.primary.black} className='relative top-1' />;
    } else {
      return <div className='relative'>{getIconForSalesItem(categorySlug)}</div>;
    }
  };

  const getPrice = () => {
    if (hasTwoPrices) {
      return <span className='ml-auto text-signal-success'>{formatNorwegianCurrencyAmount(newPrice, 0)}</span>;
    } else {
      return <span className='ml-auto '>{formatNorwegianCurrencyAmount(price, 0)}</span>;
    }
  };

  return (
    <div className='flex flex-col'>
      <div className={cn('flex w-full text-4 opacity-70 gap-2', !isAddon && 'text-bold text-4.5')}>
        {getIcon()}
        <span>{name}</span>

        {getPrice()}
      </div>

      {hasTwoPrices && (
        <div className={cn('flex w-full text-4 opacity-70', !isAddon && 'text-bold text-4.5')}>
          <span className='ml-8 text-3'>
            <Trans>Original price</Trans>
          </span>
          <span className='ml-auto '>{formatNorwegianCurrencyAmount(price, 0)}</span>
        </div>
      )}
    </div>
  );
};

const TotalPrice = ({
  totalPrice,
  timeWindow
}: {
  totalPrice: number;
  timeWindow: AvailableBookingTimeWindow | null;
}) => {
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex content-between'>
        <h1 className='text-4'>
          <Trans>Delivery</Trans>
        </h1>
        <span className='ml-auto '>{formatNorwegianCurrencyAmount(timeWindow?.price || 0, 0)}</span>
      </div>

      <div className='flex content-between'>
        <h1 className='text-bold text-4'>
          <Trans>Total sum including VAT</Trans>
        </h1>
        <span className='text-bold ml-auto'>{formatNorwegianCurrencyAmount(totalPrice, 0)}</span>
      </div>
    </div>
  );
};

export default PriceSummary;
