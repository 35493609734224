import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { ErrorPage, LoadingScreen, NoddiChip, NoddiContainer, NoddiIcon, useIsMobile } from 'noddi-ui';
import { DateFormats, format, formatNorwegianCurrencyAmount } from 'noddi-util';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import NewAppointmentLink from '../../components/Links/NewAppointment';

export const Bookings = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const { getCurrentUserGroupId } = useAuthContext();
  const userGroupId = getCurrentUserGroupId();
  const {
    isPending,
    data: bookings,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getUserGroupBookings,
    input: { userGroupId: userGroupId as number }
  });

  if (isPending) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorPage apiError={error} />;
  }

  if (bookings.length === 0) {
    return (
      <NoddiContainer header={t`Your bookings`}>
        <p className='my-2'>
          <Trans>You have no registered appointments with us yet.</Trans>
        </p>
        <NewAppointmentLink />
      </NoddiContainer>
    );
  }

  function goToBookingDetails(bookingId: number) {
    navigate(routes.myBookingDetails.getPath({ id: bookingId }));
  }

  const sortedOnDate = bookings.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  // sort bookings list first on date then where bookings with completedAt and not paidAt are first
  const bookingsSortedByUnpaidFirst = sortedOnDate.sort((a, b) => {
    if (a.completedAt && !a.paidAt) {
      return -1;
    }
    if (b.completedAt && !b.paidAt) {
      return 1;
    }
    return 0;
  });

  return (
    <NoddiContainer header={t`Your bookings`} headerNode={<NewAppointmentLink />}>
      <div className='flex flex-col gap-3'>
        {bookingsSortedByUnpaidFirst.map((booking) => {
          const orderLinesDescriptionString = booking.orderLineDescriptions
            .map((description) => description)
            .join(', ');
          return (
            <button
              key={booking.id}
              className='flex min-w-[300px] cursor-pointer items-center justify-between rounded-lg border border-secondary-coral/60 p-4 hover:shadow-cardShadow'
              onClick={() => goToBookingDetails(booking.id)}
            >
              <div className='flex gap-5'>
                {!isMobile && (
                  <div className='rounded-lg bg-primary-pink/80'>
                    <div className='flex size-20 flex-col items-center justify-center'>
                      <p className='text-4'>{format(booking.date, DateFormats.SHORT_DAY_SHORT_MONTH)}</p>
                      <p className='text-3'>{format(booking.date, DateFormats.FULL_YEAR)}</p>
                    </div>
                  </div>
                )}

                <div className='mr-2 flex flex-col justify-center text-5'>
                  <p className='p-0 text-left text-5'>{orderLinesDescriptionString}</p>
                  <div className='flex items-center gap-1'>
                    <NoddiIcon name='Magnifier' size='small' className='pl-1  sm:pl-0' />
                    <p className='text-3'>{booking.slug}</p>
                  </div>
                  {isMobile && (
                    <div className='flex items-center gap-1'>
                      <NoddiIcon name='Calendar' size='small' className='pl-1 sm:pl-0' />
                      <p className='text-3'>{format(booking.date, DateFormats.DAY_MONTH)}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className='flex items-center gap-1'>
                {booking.completedAt ? (
                  <NoddiChip
                    label={booking.paidAt ? t`Paid` : t`Unpaid`}
                    variant={booking.paidAt ? 'tertiary' : 'quaternary'}
                  />
                ) : (
                  <p>{formatNorwegianCurrencyAmount(booking.price, 0)}</p>
                )}

                <NoddiIcon name='AltArrowRight' size='medium' />
              </div>
            </button>
          );
        })}
      </div>
    </NoddiContainer>
  );
};
