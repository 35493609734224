import { Trans, t } from '@lingui/macro';
import { OutlinedInput } from '@mui/material';

type CommentBoxProps = {
  handleChange: (s: string) => void;
  comment?: string | null;
};

const CommentBox = ({ handleChange, comment }: CommentBoxProps) => {
  return (
    <div className='flex flex-col gap-3 rounded-lg bg-primary-white px-4 py-6'>
      <p className='text-4'>
        <Trans>Add a comment for our service employee</Trans>
      </p>

      <OutlinedInput
        rows={2}
        onChange={(e) => handleChange(e.target.value)}
        fullWidth
        type='textarea'
        value={comment || ''}
        title='Delivery Code'
        placeholder={t`Instructions to the technician at arrival (e.g. where the car is parked)`}
        multiline
      />
    </div>
  );
};

export default CommentBox;
