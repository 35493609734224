import { Trans } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { BookingInfo } from 'noddi-async/src/types';
import { ApiErrorMessage, NoddiButton, NoddiIcon, OrderSummary } from 'noddi-ui';
import { formatNorwegianCurrencyAmount } from 'noddi-util';

import PaymentForm from './PaymentForm';

interface JobCompletedAndPaymentLeftProps {
  bookingInfo: BookingInfo;
  orderId: number;
}

export const JobCompletedAndPaymentLeft = ({ bookingInfo, orderId }: JobCompletedAndPaymentLeftProps) => {
  const { carOrderLines, discountOrderLines, otherOrderLines } = bookingInfo.order.orderLines;
  const {
    mutateAsync: createDineroSession,
    isPending: isDineroSessionPending,
    isSuccess: isCreateDineroSessionComplete,
    data: dineroSession,
    error
  } = noddiAsync.usePost({
    type: URLKeys.postDinteroSession
  });

  return (
    <div>
      {bookingInfo.serviceWorkerNickname && (
        <div className='flex items-center gap-2'>
          <NoddiIcon name='UserCircle' size='large' />
          <p>
            <Trans>By</Trans> {bookingInfo.serviceWorkerNickname}
          </p>
        </div>
      )}
      <div className='mt-6'>
        <OrderSummary
          showHeader={false}
          carItemLines={carOrderLines}
          discountLines={discountOrderLines}
          otherOrderLines={otherOrderLines}
        />
      </div>

      {isCreateDineroSessionComplete ? (
        <PaymentForm sessionId={dineroSession.data.sessionId} />
      ) : (
        <div className='absolute bottom-6 w-[90%] mdx:static mdx:mt-6 mdx:w-full'>
          {error && <ApiErrorMessage error={error} />}
          <NoddiButton
            endIcon='ArrowRight'
            fullWidth
            loading={isDineroSessionPending}
            onClick={async () => {
              await createDineroSession({
                orderId
              });
            }}
          >
            <p>
              <Trans>Pay</Trans> {formatNorwegianCurrencyAmount(bookingInfo.order.amountDue, 0)}
            </p>
          </NoddiButton>
        </div>
      )}
    </div>
  );
};
