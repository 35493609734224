import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { NoddiCollapseCard, colors } from 'noddi-ui';

const InfoCard = () => {
  return (
    <div>
      <NoddiCollapseCard
        backgroundColor={colors.primary.darkPurple30}
        sx={{ marginBottom: '2rem' }}
        header={
          <Typography variant='h6'>
            <Trans>Practical information about tire hotel</Trans>
          </Typography>
        }
        collapseBody={
          <ul>
            <li>
              <Trans>
                To manage your tire hotel contracts, click the <b>View and manage </b> link on the the tire hotel you
                want to manage.
                <br />
                <br />
                On the tire hotel contract details page, click <b>Manage contract</b>. You will have two options:
                <br />
                <br />
                <ul>
                  <li>
                    <b>Order tire change:</b> Select this option to have us come to you and take the current season's
                    wheel set for storage until it's time to change tires again next season.
                  </li>
                  <br />
                  <li>
                    <b>End tire hotel agreement:</b> Select this option to have the tires delivered to your home. We
                    also offer tire changes when you terminate your agreement.
                  </li>
                </ul>
              </Trans>
            </li>
          </ul>
        }
      />
    </div>
  );
};

export default InfoCard;
