import { Menu, MenuItem } from '@mui/material';
import { TireHotelContract, TireHotelOptionsResponse } from 'noddi-async/src/types';
import { colors, usePopover } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import useCleanupBooking from '../../hooks/useCleanupBooking';
import { ActionOptionDisabled, actions } from './BookingFlow/Components/actions';
import { useTireHotelContext } from './BookingFlow/context';
import usePopulateStore from './BookingFlow/hooks/usePopulateStore';
import { TireStorageBookingSteps } from './BookingFlow/tireStorageBookingSteps';

type TireHotelHeaderNodeProps = {
  buttonText: string;
  bookingActions: ActionOptionDisabled[];
  tireHotelContracts: TireHotelContract[];
  tireHotelOptions: TireHotelOptionsResponse[];
};
const TireHotelHeaderNode = ({
  buttonText,
  bookingActions,
  tireHotelContracts,
  tireHotelOptions
}: TireHotelHeaderNodeProps) => {
  const { anchorRef, handleClose, handleOpen, open } = usePopover<HTMLButtonElement>();
  const navigate = useNavigate();
  const { selectedAction, setCurrentStep, setDialogOpen, setSelectedTireHotelContracts } = useTireHotelContext();
  const { cleanupBookingData } = useCleanupBooking();
  const { prePopulateSessionStorageFromTireHotelContracts } = usePopulateStore();

  const disabled = tireHotelContracts.length === 0;

  const noneCanRenew = tireHotelOptions.every((option) => !option.tireHotel.canRenewTireHotel);
  const noneCanTerminate = tireHotelOptions.every((option) => !option.tireHotel.canTerminateTireHotel);

  if (bookingActions.every((action) => action.disabled)) {
    return null;
  }
  return (
    <>
      <button
        ref={anchorRef}
        onClick={handleOpen}
        disabled={disabled}
        style={{
          backgroundColor: disabled ? colors.systemColors.grey : colors.primary.darkPurple,
          color: 'white',
          borderRadius: '100px',
          alignItems: 'center',
          minHeight: '39px',
          padding: '0 20px',
          boxSizing: 'border-box',
          border: 'none',
          cursor: 'pointer'
        }}
      >
        {buttonText}
      </button>
      <Menu anchorEl={anchorRef.current} open={open} onClose={handleClose}>
        {bookingActions
          .filter((x) => {
            if (x.value === actions.order && noneCanRenew) {
              return false;
            }
            if (x.value === actions.cancel && noneCanTerminate) {
              return false;
            }
            return true;
          })
          .map((action, index) => (
            <MenuItem
              key={index}
              selected={action.value === selectedAction}
              disabled={action.disabled}
              onClick={(_) => {
                cleanupBookingData();
                const firstTireHotelContract = tireHotelContracts[0];

                // if there is only one regNumber, and the user selects an action that requires a single regNumber, we should open the confirm dialog
                // because there are not really any more input we need from them.
                if (firstTireHotelContract && tireHotelContracts.length === 1) {
                  setSelectedTireHotelContracts([firstTireHotelContract]);
                  setDialogOpen(true);
                  prePopulateSessionStorageFromTireHotelContracts({ tireHotelContracts: [firstTireHotelContract] });
                }

                if (action.value === actions.order) {
                  setCurrentStep(TireStorageBookingSteps.address);
                  navigate(routes.tireStorageBooking.getPath());
                }

                if (action.value === actions.cancel) {
                  setCurrentStep(TireStorageBookingSteps.returnChoices);
                  navigate(routes.tireStorageCancelBooking.getPath());
                }
              }}
            >
              {action.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};
export default TireHotelHeaderNode;
