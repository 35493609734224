import { Stack, Typography } from '@mui/material';
import { ServerTypes } from 'noddi-async';
import { BookingInfoStatus } from 'noddi-async/src/types';
import { colors } from 'noddi-ui';
import { DateFormats, format } from 'noddi-util';
import styled, { keyframes } from 'styled-components';

import RelevantQuestionsForBooking from './RelevantQuestionsForBooking';

const Circle = styled.div`
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  background-color: ${colors.primary.darkPurple};
  border-radius: 50%;
  position: relative;
`;

const rotate = keyframes`
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
`;

const Ringring = styled.div`
  border: 3px solid ${colors.primary.darkPurple};
  -webkit-border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: -5px;
  top: -5px;
  -webkit-animation: ${rotate} 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
`;

export function BookingContentBasedOnStatus({
  status,
  bookingInfo,
  currentEstimatedArrivalTime
}: {
  status: BookingInfoStatus;
  bookingInfo: ServerTypes.BookingInfo;
  currentEstimatedArrivalTime?: { start?: string; end?: string };
}) {
  const delayInMinutes = Math.floor(bookingInfo.delayInSeconds / 60);
  const showDelayedMessage = delayInMinutes > 30;
  const showAheadOfScheduleMessage = delayInMinutes < -30;
  const showCommunicatedTimes = !showAheadOfScheduleMessage && !showDelayedMessage;
  const hasLiveEstimate = currentEstimatedArrivalTime?.end && currentEstimatedArrivalTime?.start;

  const CommunicatedArrivalTimes = () => (
    <>
      {bookingInfo.communicatedArrivalWindow.start && bookingInfo.communicatedArrivalWindow.end ? (
        <Stack direction='row'>
          {hasLiveEstimate && (
            <Circle style={{ marginRight: 12, marginTop: 4 }}>
              <Ringring />
            </Circle>
          )}
          <Typography>
            {bookingInfo.serviceWorkerNickname
              ? `Vår tekniker ${bookingInfo.serviceWorkerNickname} forventes`
              : 'Vi for venter'}{' '}
            å komme til deg mellom <b>{format(bookingInfo.communicatedArrivalWindow.start, DateFormats.TIME)}</b> og{' '}
            <b>{format(bookingInfo.communicatedArrivalWindow.end, DateFormats.TIME)}</b>
          </Typography>
        </Stack>
      ) : null}

      {!hasLiveEstimate && (
        <Typography style={{ marginTop: 12 }}>Du vil få beskjed når vi er på vei til deg!</Typography>
      )}
    </>
  );
  const CurrentEstimatedTimes = () =>
    hasLiveEstimate ? (
      <Stack direction='row'>
        <Circle style={{ marginRight: 12, marginTop: 4 }}>
          <Ringring />
        </Circle>
        <Typography width='80%'>
          {showAheadOfScheduleMessage
            ? 'Vi er litt foran skjemaet og ankommer muligens litt før den opprinnlige tiden.'
            : 'Vi er litt bak skjemaet og ankommer muligens etter den opprinnlige tiden.'}{' '}
          {bookingInfo.serviceWorkerNickname
            ? `Vår tekniker ${bookingInfo.serviceWorkerNickname} forventes`
            : 'Vi for venter'}{' '}
          å komme til deg mellom{' '}
          <b>
            kl {currentEstimatedArrivalTime?.start ? format(currentEstimatedArrivalTime.start, DateFormats.TIME) : '-'}
          </b>{' '}
          og{' '}
          <b>kl {currentEstimatedArrivalTime?.end ? format(currentEstimatedArrivalTime.end, DateFormats.TIME) : '-'}</b>
        </Typography>
      </Stack>
    ) : (
      // fallback to communicated times if we dont have currentEstimatedArrivalTime
      <CommunicatedArrivalTimes />
    );

  switch (status) {
    case BookingInfoStatus.DRAFT:
      return <></>;
    case BookingInfoStatus.CANCELLED:
    case BookingInfoStatus.UNABLE_TO_COMPLETE:
      return (
        <Stack>
          <Typography>
            {' '}
            {status === BookingInfoStatus.UNABLE_TO_COMPLETE
              ? 'Vi hadde ikke mulighet til å fullføre denne bookingen. Ta kontakt med oss for mer informasjon'
              : 'Denne bookingen står som avlyst i våre systemer'}
          </Typography>
        </Stack>
      );
    case BookingInfoStatus.ASSIGNED_TO_ROUTE:
    case BookingInfoStatus.CONFIRMED:
      return (
        <>
          {showCommunicatedTimes ? <CommunicatedArrivalTimes /> : <CurrentEstimatedTimes />}

          <div style={{ marginTop: 22 }}>
            <RelevantQuestionsForBooking />
          </div>
        </>
      );

    default:
      return <div />;
  }
}
