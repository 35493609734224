import { t } from '@lingui/macro';
import { Skeleton } from '@mui/material';
import { AvailableBookingTimeWindow, AvailableBookingTimeWindowsByDate } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { DateFormats, differenceBetweenDates, format, formatNorwegianCurrencyAmount } from 'noddi-util';
import styled from 'styled-components';

import { cn } from '../../../helpers/utilts';

const isTimeWindowUnavailable = ({ timeWindow }: { timeWindow: AvailableBookingTimeWindow }) => {
  return timeWindow.isTimeWindowTooShort || timeWindow.isCapacityFull || timeWindow.isClosed;
};

const DateCard = ({ date, isSelected }: { date: string; isSelected: boolean }) => {
  const parsedDay = new Date(date);
  const parsedToday = new Date();
  const isToday = differenceBetweenDates(parsedDay, parsedToday, 'days') === 0;
  const isTomorrow = differenceBetweenDates(parsedDay, parsedToday, 'days') === 1;

  const getDayFormat = () => {
    if (isToday) {
      return t`Today`;
    }
    if (isTomorrow) {
      return t`Tomorrow`;
    }
    return format(parsedDay, DateFormats.SHORT_WEEKDAY_DAY_MONTH);
  };

  return (
    <div className='sticky top-0 z-50 flex h-14 w-full flex-col items-center justify-center gap-2 py-2 mdx:bg-primary-white'>
      <p className={cn('text-nowrap', isSelected && 'font-bold')}>{getDayFormat()}</p>
    </div>
  );
};

const StyledDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 100%;
  position: relative;
`;

export type OnSelectTimeWindowProps = {
  onAvailableSelect: (bookingTimeWindow: AvailableBookingTimeWindow) => void;
  onUnavailableSelect?: (bookingTimeWindow: AvailableBookingTimeWindow) => void;
};

type TimeWindowCardProps = {
  timeWindow: AvailableBookingTimeWindow | null;
  isActive: boolean;
  isLoading: boolean;
} & OnSelectTimeWindowProps;

const TimeWindowCard = ({
  timeWindow,
  isLoading,
  onAvailableSelect,
  onUnavailableSelect,
  isActive
}: TimeWindowCardProps) => {
  const { isSuperUser } = useAuthContext();

  const isNull = timeWindow === null;
  const isUnavailable = timeWindow !== null && isTimeWindowUnavailable({ timeWindow });

  const handleOnClick = () => {
    if (!timeWindow) {
      return;
    }
    if (isUnavailable) {
      onUnavailableSelect?.(timeWindow);
    } else {
      onAvailableSelect(timeWindow);
    }
  };

  const getSubText = () => {
    if (isUnavailable || isNull) {
      return t`Sold out`;
    }

    return formatNorwegianCurrencyAmount(timeWindow.price, 0);
  };

  if (isLoading) {
    return <Skeleton variant='rounded' height={44} width='100%' />;
  }

  return (
    <button
      onClick={handleOnClick}
      className={cn(
        'mx-auto flex h-13 w-[70px] flex-col items-center justify-center gap-7 self-stretch rounded-xl p-2  sm:hover:text-primary-white ',
        isActive
          ? 'bg-primary-darkPurple text-primary-white '
          : 'bg-systemColors-purpleBg text-primary-black sm:hover:bg-primary-darkPurple80',
        isNull ? 'invisible' : '',
        isUnavailable ? 'bg-secondary-orange/60 pointer-events-none text-secondary-black' : '',
        isSuperUser && isUnavailable ? 'pointer-events-auto' : ''
      )}
    >
      <p className='text-center'>{getSubText()}</p>
    </button>
  );
};

type BookingTimeWindowPickerDateProps = {
  timeWindowsByDate: AvailableBookingTimeWindowsByDate;
  isLoading: boolean;
  selectedTimeWindowId: number | null | undefined;
  selectedTimeWindowDate?: string;
} & OnSelectTimeWindowProps;

const BookingTimeWindowPickerDate = ({
  timeWindowsByDate,
  selectedTimeWindowId,
  selectedTimeWindowDate,
  ...rest
}: BookingTimeWindowPickerDateProps) => {
  return (
    <StyledDateContainer>
      <DateCard date={timeWindowsByDate.date} isSelected={selectedTimeWindowDate === timeWindowsByDate.date} />
      <div className='relative bottom-3 h-0.25 w-50 bg-secondary-silverGray' />

      <div className='flex w-full flex-col items-center justify-center gap-7 sm:gap-12 '>
        {timeWindowsByDate?.timeWindows?.map((timeWindow, idx) => {
          return (
            <TimeWindowCard
              key={`${timeWindow?.id}-${idx}`}
              timeWindow={timeWindow}
              isActive={selectedTimeWindowId !== undefined && timeWindow?.id === selectedTimeWindowId}
              {...rest}
            />
          );
        })}
      </div>
    </StyledDateContainer>
  );
};

export default BookingTimeWindowPickerDate;
