import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { BookingInfoStatus, BookingInfo as BookingInfoType } from 'noddi-async/src/types';
import { ErrorPage, LoadingScreen, useIsMobile } from 'noddi-ui';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { BookingContentBasedOnStatus } from './BookingContentBasedOnStatus';
import FeedbackAfterCompletedBooking from './FeedbackAfterCompletedBooking';
import { JobCompletedAndPaymentLeft } from './JobCompletedAndPaymentLeft';
import PaymentInfo from './PaymentInfo';

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const BookingContainer = styled.div<{ isMobile: boolean }>`
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  padding: 20px 20px 64px 20px;
  height: 100%;
  min-height: ${(p) => (p.isMobile ? '92vh' : '90vh')};
  display: flex;
  flex-direction: column;
`;

function getContent({
  bookingInfo,
  currentEstimatedArrivalTime,
  orderId
}: {
  bookingInfo: BookingInfoType;
  currentEstimatedArrivalTime?: { start?: string; end?: string };
  orderId: number;
}) {
  const isInvoiced = bookingInfo.order.status === 'Invoiced';
  const amountDue = bookingInfo.order.amountDue;
  const hasPayed = isInvoiced && amountDue <= 0;
  const jobCompletedAndPaymentLeft = isInvoiced && amountDue > 0;
  const showFeedbackContent =
    !bookingInfo.feedback && [BookingInfoStatus.COMPLETED].includes(bookingInfo.status) && hasPayed;

  const title = jobCompletedAndPaymentLeft ? t`Job completed` : t`Your booking`;
  const Title = () => <h1 className='text-13'>{title}</h1>;

  if (showFeedbackContent) {
    return (
      <>
        <Title />
        <FeedbackAfterCompletedBooking bookingId={bookingInfo.id} />
      </>
    );
  }

  if (hasPayed) {
    return (
      <>
        <Title />
        <PaymentInfo orderId={orderId} paymentMethod={bookingInfo.order.paymentMethod} />
      </>
    );
  }

  if (jobCompletedAndPaymentLeft) {
    return (
      <>
        <Title />
        <JobCompletedAndPaymentLeft bookingInfo={bookingInfo} orderId={orderId} />
      </>
    );
  }

  // if none of the above is true we show the booking content based on status
  return (
    <>
      <Title />
      <div className='mt-4 w-full'>
        <BookingContentBasedOnStatus
          status={bookingInfo.status}
          bookingInfo={bookingInfo}
          currentEstimatedArrivalTime={currentEstimatedArrivalTime}
        />
      </div>
    </>
  );
}

const BookingInfo = () => {
  const data = useParams<{ slug: string }>();
  const isMobile = useIsMobile();
  const [orderId, setOrderId] = useState<number | null>(null);

  const {
    data: bookingInfo,
    isPending: isBookingInfoPending,
    isSuccess: isBookingInfoSuccess,
    error: bookingInfoError
  } = noddiAsync.useGet({
    type: URLKeys.getBookingInfo,
    input: { slug: data.slug ?? '' }
  });

  useEffect(() => {
    if (!bookingInfo) {
      return;
    }
    setOrderId(bookingInfo.order.id);
  }, [bookingInfo]);

  const shouldRefetch = isBookingInfoSuccess && !!bookingInfo.delayInSeconds;

  const { data: currentEstimatedArrivalTime } = noddiAsync.useGet({
    type: URLKeys.getCurrentEstimatedArrivalTime,
    input: { slug: data.slug ?? '' },
    queryConfig: {
      refetchInterval: shouldRefetch ? 60000 * 2 : false,
      refetchOnWindowFocus: shouldRefetch
    }
  });

  if (isBookingInfoPending) {
    return <LoadingScreen />;
  }

  if (bookingInfoError) {
    return <ErrorPage apiError={bookingInfoError} />;
  }

  return (
    <TopContainer>
      <InnerContainer>
        <BookingContainer isMobile={isMobile}>
          {getContent({ bookingInfo, currentEstimatedArrivalTime, orderId: orderId as number })}
        </BookingContainer>
      </InnerContainer>
    </TopContainer>
  );
};

export default BookingInfo;
