import { Trans, t } from '@lingui/macro';
import { NoddiAsyncError } from 'noddi-async/src/types/shared/error';
import { useEffect, useRef, useState } from 'react';

import { isLocalEvn } from '../../../../../../web/noddi-customer/src/environment';
import { NoddiButton, NoddiTextInput } from '../../Elements';
import { ApiErrorMessage } from '../../Elements/Errors';

const VerificationCodeForm = ({
  phoneNumber,
  onClick,
  isLoading,
  verificationSuccess,
  error
}: {
  isLoading: boolean;
  phoneNumber: string;
  onClick: (params: { code: string; phoneNumber: string }) => void;
  verificationSuccess: boolean;
  error: NoddiAsyncError | null;
  hideText?: boolean;
}) => {
  const [verificationCode, setVerificationCode] = useState(isLocalEvn ? '123456' : '');

  //Send the login request for login immediately
  useEffect(() => {
    if (isLocalEvn) {
      onClick({ code: verificationCode, phoneNumber });
    }
  }, []);

  const verificationCodeIsValid = () => {
    return verificationCode.length === 6;
  };

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.focus();
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }, [ref]);

  return (
    <div className='flex flex-col gap-3'>
      <NoddiTextInput
        color='primary'
        value={verificationCode}
        onChange={(e) => {
          const newValue = e.target.value;

          if (newValue) {
            setVerificationCode(newValue);
          } else {
            setVerificationCode('');
          }
        }}
        ref={ref}
        inputProps={{
          minLength: 6,
          maxLength: 6,
          inputMode: 'numeric',
          pattern: '[0-9]*'
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (verificationCodeIsValid() && event.key === 'Enter') {
            onClick({ code: verificationCode, phoneNumber });
          }
        }}
        placeholder={t`Enter code`}
      />
      <div className='flex justify-end gap-4'>
        {error && (
          <div className='flex justify-center'>
            <ApiErrorMessage error={error} />
          </div>
        )}
        <NoddiButton
          disabled={!verificationCodeIsValid() || isLoading || verificationSuccess}
          loading={isLoading}
          onClick={() => onClick({ code: verificationCode, phoneNumber })}
        >
          <Trans>Confirm</Trans>
        </NoddiButton>
      </div>
    </div>
  );
};

export { VerificationCodeForm };
