import { Trans } from '@lingui/macro';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  EmailLogin,
  NoddiIcon,
  NoddiLogo,
  SMSLogin,
  SectionSpacer,
  UserRegistrationForm,
  useGetScreenDimensions,
  useLoginRedirect
} from 'noddi-ui';
import { useState } from 'react';
import styled from 'styled-components';

import routes from '../../appRoutes';
import tracking from '../../tracking';

const Container = styled.div`
  min-width: 330px;
  max-width: 550px;
  @media (width < 550px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 24px 24px 0px 24px;
`;

const Login = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const { screenSize } = useGetScreenDimensions();
  const [isSmsLogin, _] = useState(true); // if false, email login

  const containerHeight = screenSize.height * 0.75;

  // listens to auth context and redirects to booking info page if logged in
  useLoginRedirect({ homePath: routes.homePageBookings.getPath() });

  return (
    <Stack justifyContent='center' alignItems='center' minHeight={containerHeight} marginY={10}>
      <Container>
        <Card elevation={16}>
          <Header>
            <div style={{ margin: 'auto 0' }}>
              <Typography variant='h3'>
                <Trans>Sign in</Trans>
              </Typography>
            </div>
            <NoddiLogo />
          </Header>

          <CardContent style={{ paddingTop: '0px' }}>
            {isSmsLogin ? (
              <SMSLogin
                className='mt-4'
                userRegistrationForm={(phoneNumber) => (
                  <UserRegistrationForm
                    phoneNumber={phoneNumber}
                    skipPasswordCreation
                    trackUserIdentity={(user) => {
                      tracking.trackUserIdentity(user);
                    }}
                  />
                )}
              />
            ) : (
              <SectionSpacer>
                <div style={{ width: '100%' }}>
                  <EmailLogin setErrorMessage={setErrorMessage} />
                </div>
              </SectionSpacer>
            )}
            <Typography style={{ marginTop: 12 }} variant='body2'>
              <Trans>Get in touch if you have problems logging in. You can reach us at hei@noddi.no</Trans>
            </Typography>
            {errorMessage && (
              <p className='mt-3 text-start text-signal-danger'>
                <NoddiIcon name='Exclamation' className='mb-1' size='medium' /> {errorMessage}
              </p>
            )}
          </CardContent>
        </Card>
      </Container>
    </Stack>
  );
};

export default Login;
