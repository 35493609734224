import { Trans } from '@lingui/macro';
import { Box, Stack, Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { CouponCard, LoadingScreen, SMSLogin, UserRegistrationForm, colors, useIsMobile } from 'noddi-ui';
import { useParams } from 'react-router-dom';

import { ContentContainer, InnerContainer, TopContainer } from '../../components/Layouts/Container';
import tracking from '../../tracking';
import ActivateCampaign from '../Campaigns/ActivateCampaign';

const Referrals = () => {
  const data = useParams<{ slug: string }>();
  const { isLoggedIn, canActivateCouponsForNewUsersOnly, userData } = useAuthContext();
  const isMobile = useIsMobile();

  const safeSlug = data.slug?.toLocaleLowerCase();

  const {
    data: campaignData,
    isPending: isCampaignPending,
    isSuccess: isCampaignSuccess
  } = noddiAsync.useGet({ type: URLKeys.getCampaignFromSlug, input: { slug: safeSlug } });

  if (isCampaignPending) {
    return <LoadingScreen />;
  }

  if (!isCampaignSuccess) {
    return (
      <Box>
        <Stack>
          <Typography>Obs noe gikk galt..</Typography>
        </Stack>
      </Box>
    );
  }

  const from = campaignData.name;

  const isOwnReferralCode = data.slug === userData?.user.referralCode;

  return (
    <TopContainer>
      <InnerContainer>
        <ContentContainer isMobile={isMobile}>
          {isLoggedIn && campaignData.isForNewUserOnly && !canActivateCouponsForNewUsersOnly ? (
            <Stack>
              {isOwnReferralCode ? (
                <h2 className='text-8'>
                  <Trans>You cannot activate your own referral code</Trans>
                </h2>
              ) : (
                <h4 className='mt-2 text-4'>
                  <Trans>Your user cannot activate coupons from this campaign</Trans>
                </h4>
              )}

              <Typography variant='subtitle1' mt={2} style={{ color: colors.systemColors.grey }}>
                <Trans>This promotion is only for new users without existing coupons</Trans>
              </Typography>
            </Stack>
          ) : (
            <>
              <Typography variant='h1'>
                <Trans>Hurray</Trans> 🎉{' '}
              </Typography>
              <Typography variant='subtitle1' fontSize='28px'>
                <Trans>You have been invited by</Trans> {from}
              </Typography>
              {!isLoggedIn && (
                <>
                  <Typography variant='h5' mt={3}>
                    <Trans>Please log in to activate your coupons</Trans>
                  </Typography>
                  <Typography variant='subtitle1' mt={2} style={{ color: colors.systemColors.grey }}>
                    <Trans>If you don't already have a user, we will automatically create one for you</Trans>
                  </Typography>
                  <SMSLogin
                    className='mt-4'
                    userRegistrationForm={(phoneNumber) => (
                      <UserRegistrationForm
                        phoneNumber={phoneNumber}
                        skipPasswordCreation
                        trackUserIdentity={(user) => {
                          tracking.trackUserIdentity(user);
                        }}
                      />
                    )}
                  />
                </>
              )}

              {isLoggedIn && <ActivateCampaign campaign={campaignData} />}
              {campaignData.couponGroup && (
                <>
                  <Typography variant='h5' mt={2}>
                    <Trans>The coupons you get from</Trans> {from}
                  </Typography>
                  {campaignData.couponGroup.couponTemplates.map((couponTemplate) => (
                    <CouponCard
                      key={couponTemplate.id}
                      namePublic={couponTemplate.namePublic}
                      descriptionPublic={couponTemplate.descriptionPublic}
                      amount={couponTemplate.amount}
                      amountPercentage={couponTemplate.amountPercentage}
                      containerSx={{ marginTop: '22px' }}
                    />
                  ))}
                </>
              )}

              <Stack direction='row' marginTop={2}>
                <Typography fontSize='20px'>
                  <Trans>
                    Read more about our recruitment system <a href='https://noddi.no/verving'>here</a>
                  </Trans>
                </Typography>
              </Stack>
            </>
          )}
        </ContentContainer>
      </InnerContainer>
    </TopContainer>
  );
};

export default Referrals;
