import { Trans } from '@lingui/macro';
import { ServerTypes } from 'noddi-async';
import { NoddiButton, useIsMobile } from 'noddi-ui';
import { cn } from 'noddi-ui/src/helpers/utilts';
import { formatNorwegianCurrencyAmount } from 'noddi-util';
import { useState } from 'react';

import { useBookingContext } from '../../../../contexts/BookingContext';
import useSelectSalesItem from '../../../../hooks/useSelectSalesItem';
import { SelectedCar } from '../../interfaces';
import { IncompatibleDialog } from './IncompatibleDialog';

type ServiceItemProps = {
  name: string;
  price: number;
  description?: string;
  car: SelectedCar;
  salesItem: ServerTypes.AvailableSalesItem;
  onSalesItemClick: () => void;
};

const SalesItem = ({ name, description, price, car, salesItem, onSalesItemClick }: ServiceItemProps) => {
  const [openIncompatibleDialog, setOpenIncompatibleDialog] = useState(false);
  const isMobile = useIsMobile();
  const [showFullDescription, setShowFullDescription] = useState(isMobile ? false : true);
  const { isSelected } = useSelectSalesItem();
  const isAlreadySelected = isSelected({ licensePlateNumber: car.licensePlateNumber, salesItem });
  const { incompatibleServiceCategories } = useBookingContext();

  const trimmedDescription = description && description?.length > 70 ? `${description.slice(0, 70)}...` : description;

  const isCurrentlyIncompatible = incompatibleServiceCategories.find(
    (category) => category.serviceCategoryId === salesItem.serviceCategory.id
  );

  const ShowMoreLessDescriptionLabel = (
    <>
      {description && description?.length > 70 && isMobile && (
        <span
          className='ml-2 cursor-pointer text-4 underline opacity-70'
          onClick={() => setShowFullDescription((prev) => !prev)}
        >
          {!showFullDescription ? <Trans>Show more</Trans> : <Trans>Show less</Trans>}
        </span>
      )}
    </>
  );

  return (
    <>
      <IncompatibleDialog
        salesItem={salesItem}
        open={openIncompatibleDialog}
        onClose={() => {
          setOpenIncompatibleDialog(false);
        }}
      />
      <div
        className={cn(
          'relative flex min-h-40 w-full gap-4  rounded-lg p-5 px-10 transition-all sm:min-h-[170px]',
          isAlreadySelected ? 'bg-primary-purple' : 'bg-primary-pink40'
        )}
      >
        <div
          className={cn('flex flex-col gap-1 w-full', isAlreadySelected ? 'text-primary-white' : 'text-primary-black')}
        >
          <p className='text-5'>{name}</p>
          <p className='text-4'>{formatNorwegianCurrencyAmount(price)}</p>
          <p className=' text-4 opacity-70 '>
            {showFullDescription ? description : trimmedDescription}
            {ShowMoreLessDescriptionLabel}
          </p>

          <div className='-ml-3 mt-4 w-fit'>
            {isCurrentlyIncompatible ? (
              <NoddiButton
                variant='link'
                size='small'
                onClick={() => {
                  setOpenIncompatibleDialog(true);
                }}
              >
                <Trans>Why am I not able to select this?</Trans>
              </NoddiButton>
            ) : (
              <NoddiButton
                variant={isAlreadySelected ? 'secondary' : 'primary'}
                startIcon={isAlreadySelected ? 'Minus' : 'Plus'}
                size='small'
                onClick={onSalesItemClick}
              >
                {isAlreadySelected ? <Trans>Remove</Trans> : <Trans>Add</Trans>}
              </NoddiButton>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { SalesItem };
