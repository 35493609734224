import { MembershipDiscountProps } from 'noddi-async/src/types';
import { NoddiIcon, colors } from 'noddi-ui';
import { roundNumber } from 'noddi-util';

import { ActiveCoupon } from '../../../../stores/CouponStore';

interface Item {
  id: string;
  licensePlateNumber: string;
  name: string;
  price: number;
  addons?: string[];
}

interface UiCategory {
  name: string;
  items: Item[];
}

interface ServiceCategory {
  serviceCategoryName: string;
  uiCategory: UiCategory[];
}

export type SummaryData = ServiceCategory[];

export const getIconForSalesItem = (category?: string) => {
  switch (category) {
    case 'wheel_services':
      return <NoddiIcon name='WheelAngle' size='medium' color={colors.primary.black} />;
    case 'wash_services':
      return <NoddiIcon name='Drop' size='medium' color={colors.primary.black} />;
    case 'packages':
      return <NoddiIcon name='Heart' size='medium' color={colors.primary.black} />;
    default:
      return <NoddiIcon name='Wheel' size='medium' color={colors.primary.black} />;
  }
};

export const getNewPriceForSalesItems = (
  price: number,
  discount?: MembershipDiscountProps | undefined,
  coupon?: ActiveCoupon | undefined
) => {
  let discountAmount = 0;
  let couponAmount = 0;

  if (discount?.percentage) {
    discountAmount = (Number(discount.percentage) / 100) * price;
  } else if (discount?.price) {
    discountAmount = discount.price;
  }

  if (coupon?.amountPercentage) {
    couponAmount = (Number(coupon.amountPercentage) / 100) * price;
  } else if (coupon?.amount) {
    couponAmount = coupon?.amount;
  }

  return roundNumber({
    number: price - discountAmount - couponAmount,
    decimalPlaces: 2
  });
};
