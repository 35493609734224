import { Trans, t } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { TireHotelContract } from 'noddi-async/src/types';
import { ArrowLink, NoddiChip, NoddiIcon, SelectableCard, getCarDisplayName } from 'noddi-ui';
import { SelectableCardProps } from 'noddi-ui/src/components/Elements/Cards/SelectableCard';
import { DateFormats, format } from 'noddi-util';

import routes from '../../appRoutes';

type ContractCardProps = {
  onSelect?: (tireHotelContracts: TireHotelContract) => void;
  tireHotelContract: TireHotelContract;
} & Omit<SelectableCardProps, 'onSelect'>;
const ContractCard = ({
  onSelect,
  tireHotelContract,
  selected,
  mode: incomingMode,
  disabled,
  ...rest
}: ContractCardProps) => {
  const displayName = getCarDisplayName(tireHotelContract.car);
  const contractHasNotEnded = !tireHotelContract.endedAt;
  const isActive = tireHotelContract.startedAt && contractHasNotEnded;
  const mode = disabled ? 'view' : incomingMode;

  return (
    <SelectableCard
      selected={selected}
      mode={mode}
      disabled={disabled}
      onClick={() => {
        if (onSelect && mode === 'select') {
          onSelect(tireHotelContract);
        }
      }}
      {...rest}
    >
      <div className='flex flex-col gap-4'>
        <Typography variant='h6'>{displayName}</Typography>

        <Stack direction='row' alignItems='center' gap={1}>
          <NoddiIcon name='ClockCircle' size='medium' />
          <Typography textAlign='center'>
            {tireHotelContract.startedAt
              ? `${t`Started`}: ${format(tireHotelContract.startedAt, DateFormats.DOTTED_DATE)}`
              : `${t`Scheduled start`}: ${format(tireHotelContract.startsAt, DateFormats.DOTTED_DATE)}`}
          </Typography>
        </Stack>

        <Stack direction='row' alignItems='center' gap={1}>
          <NoddiIcon name='HourGlass' size='medium' />
          <Typography>
            <Trans>Storage included until the next tire change</Trans>
          </Typography>
        </Stack>

        <div className='flex flex-col items-end justify-between gap-3 xxs:flex-row'>
          {contractHasNotEnded && (
            <NoddiChip label={isActive ? t`Active agreement` : t`Upcoming appointment`} variant='primary' />
          )}
          <ArrowLink to={routes.tireStorageDetail.getPath({ id: tireHotelContract.id })} text={t`View and manage`} />
        </div>
      </div>
    </SelectableCard>
  );
};

export default ContractCard;
