import { Trans } from '@lingui/macro';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { ServerTypes } from 'noddi-async';
import { NoddiButton } from 'noddi-ui';
import { Link } from 'react-router-dom';

import { useBookingContext } from '../../../../contexts/BookingContext';
import { useSalesItems } from '../../../../hooks/useSalesItems';

export interface IncompatibleDialogProps {
  open: boolean;
  onClose: () => void;
  salesItem: ServerTypes.AvailableSalesItem;
}

export const IncompatibleDialog = ({ open, onClose, salesItem }: IncompatibleDialogProps) => {
  const { incompatibleServiceCategories } = useBookingContext();
  const { getAllAvailableSalesItemsOrUndefined } = useSalesItems();

  // find all names of incompatible service categories for this sales item
  const incompatibleServiceCategoryIds = incompatibleServiceCategories.flatMap(
    (category) => category.incompatibleCategoriesIds
  );

  // find all sales items with the incompatible service categories
  const incompatibleSalesItemNames =
    getAllAvailableSalesItemsOrUndefined()
      ?.filter((item) => incompatibleServiceCategoryIds.includes(item.serviceCategory.id))
      .map((item) => item.name) ?? [];

  const handleClose = () => {
    onClose();
  };

  const DisplayList = ({ incompatibles }: { incompatibles: string[] }) => {
    return (
      <ul>
        {incompatibles.map((item: string) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    );
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth='md' sx={{ zIndex: 1500 }}>
      <DialogContent>
        <div style={{ padding: '1em' }}>
          <Typography variant='body1'>
            <Trans>
              Unfortunately, we currently have no workers in your area who can perform <b>{salesItem.name}</b> in
              combination with
            </Trans>
          </Typography>
          {incompatibleSalesItemNames.length > 0 ? (
            <DisplayList incompatibles={Array.from(new Set(incompatibleSalesItemNames))} />
          ) : (
            <>
              <Trans>Failed to load category names</Trans>
            </>
          )}
          <Typography variant='body1'>
            <Trans>
              If you want this combination, you can try to make two different orders. Note that if this is possible, we
              cannot guarantee that the services will be performed at the same time.
            </Trans>
          </Typography>
          <Typography variant='body1'>
            <Trans>
              Do not hesitate to <Link to='https://www.noddi.no/hjelp-og-kontakt'> get in touch</Link> if you have any
              questions{' '}
            </Trans>
          </Typography>
        </div>

        <DialogActions>
          <NoddiButton onClick={handleClose} style={{ marginTop: '24px' }} fullWidth>
            OK
          </NoddiButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
