import { Typography } from '@mui/material';
import { NoddiButton, NoddiIcon, colors, useIsMobile } from 'noddi-ui';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import routes from '../../appRoutes';

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const BookingContainer = styled.div<{ isMobile: boolean }>`
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  padding: 48px 20px 64px 20px;
  height: 100%;
  min-height: ${(p) => (p.isMobile ? '92vh' : '90vh')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type ErrorTypes = 'capture' | 'cancelled' | 'authorization' | 'failed';

function getErrorTextBasedOnErroType(error: ErrorTypes) {
  switch (error) {
    case 'cancelled':
      return 'Betaling avbrutt';
    case 'authorization':
    case 'failed':
    case 'capture':
      return 'Betaling feilet';
    default:
      return 'Betaling feilet';
  }
}

const PaymentInfo = () => {
  const navigate = useNavigate();
  const data = useParams<{ slug: string }>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const transactionId = searchParams.get('transaction_id');
  const error = searchParams.get('error') as ErrorTypes;

  const isMobile = useIsMobile();

  const isPaymentSuccessful = Boolean(transactionId) && error !== 'capture';

  return (
    <TopContainer>
      <InnerContainer>
        <BookingContainer isMobile={isMobile}>
          <div style={{ marginBottom: 24 }}>
            {isPaymentSuccessful ? (
              <>
                <Typography variant='h1' mb={2} textAlign='center'>
                  Betaling gjennomført
                </Typography>
                <>
                  <IconContainer>
                    <NoddiIcon
                      name='CheckRounded'
                      color={colors.primary.orange}
                      size='extraLarge'
                      className='size-25'
                    />
                  </IconContainer>
                </>
              </>
            ) : (
              <>
                <Typography variant='h1' mb={2} textAlign='center'>
                  Betaling feilet
                </Typography>
                <Typography style={{ marginTop: 12 }} textAlign='center'>
                  {getErrorTextBasedOnErroType(error)}
                </Typography>
                {Boolean(transactionId) && (
                  <>
                    <IconContainer>
                      <NoddiIcon name='Alert' color={colors.primary.orange} className='-mx-6 size-25' />
                    </IconContainer>
                  </>
                )}
                <NoddiButton
                  style={{ marginTop: '24px' }}
                  fullWidth
                  onClick={() => {
                    navigate(routes.bookingInfo.getPath({ slug: data.slug! }));
                  }}
                >
                  Prøv på nytt
                </NoddiButton>
              </>
            )}
          </div>
        </BookingContainer>
      </InnerContainer>
    </TopContainer>
  );
};

export default PaymentInfo;
