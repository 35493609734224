import { t } from '@lingui/macro';

export const actions = {
  order: 'order',
  cancel: 'cancel'
} as const;
export type Action = keyof typeof actions;

export type ActionOptionDisabled = {
  value: Action;
  label: string;
  disabled?: boolean;
  disabledReason?: string | null;
};

export const getHeaderTexts = () => {
  return {
    [actions.order]: t`Select the cars you want to order a tire change for`,
    [actions.cancel]: t`Select the cars for which you want to end tire hotel agreement`
  } as const;
};
