import { Trans } from '@lingui/macro';
import { NoddiButton, StickyBottomBarWrapper } from 'noddi-ui';
import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

import routes from '../../appRoutes';
import BookingProgressBar from './Steps/BookingProgressBar';
import ShoppingCart from './Steps/Summary/ShoppingCart';

interface ScreenProps {
  onNextClicked?: () => void;
  onPreviousClick?: () => void;
  disableNextButton?: boolean;
  hideBackButton?: boolean;
  hideNextButton?: boolean;
  hideShoppingCart?: boolean;
}

const StickyBottomBar = ({
  onNextClicked,
  disableNextButton,
  hideBackButton,
  hideNextButton,
  hideShoppingCart
}: PropsWithChildren<ScreenProps>) => {
  const { pathname } = useLocation();

  const isTimePickerStep = pathname.includes(routes.newBookingTimeSlotStep.getBasePath());

  if (isTimePickerStep) {
    return null;
  }

  return (
    <StickyBottomBarWrapper>
      {!hideBackButton && (
        <div className='w-full max-w-32'>
          <BookingProgressBar />
        </div>
      )}

      <div className='ml-auto flex items-center gap-4'>
        {!hideShoppingCart && <ShoppingCart />}
        {!hideNextButton && (
          <NoddiButton onClick={onNextClicked} disabled={disableNextButton} endIcon='ArrowRight'>
            <Trans>Continue</Trans>
          </NoddiButton>
        )}
      </div>
    </StickyBottomBarWrapper>
  );
};

export default StickyBottomBar;
