import { Trans, t } from '@lingui/macro';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { trimAllWhiteCharacters } from 'noddi-util';
import { KeyboardEvent, useEffect } from 'react';
import 'react-international-phone/style.css';

import { ApiErrorMessage, NoddiButton } from '../../../';
import { NoddiPhoneInput } from '../../Elements/Form/NoddiFormPhoneInput';
import { CallBackObject } from './SMSLogin';
import { isPhoneNumberValid } from './isPhoneNumberValid';

type PhoneNumberFormProps = {
  mobileNumber: string;
  setMobileNumber: (mobileNumber: string) => void;
  setCodeSentSuccessfully: (isSuccessful: true) => void;
  callBackObject?: CallBackObject;
  hideText?: boolean;
};

const PhoneNumberForm = ({
  mobileNumber,
  setMobileNumber,
  setCodeSentSuccessfully,
  // Can delete everything with callBackObject when separating the login and summary
  callBackObject,
  hideText
}: PhoneNumberFormProps) => {
  const phoneNumberDto = ({ urlEncode = false }: { urlEncode?: boolean }) => {
    const serializedMobileNumber = trimAllWhiteCharacters(mobileNumber);

    return urlEncode ? encodeURIComponent(serializedMobileNumber) : serializedMobileNumber;
  };

  const { isSuccess, isLoading, isRefetching, refetch, error } = noddiAsync.useGet({
    type: URLKeys.getPhoneNumberVerification,
    input: {
      phoneNumber: phoneNumberDto({ urlEncode: true })
    },
    queryConfig: {
      enabled: false
    }
  });

  useEffect(() => {
    if (isSuccess) {
      setCodeSentSuccessfully(true);
    }
  }, [isSuccess]);

  const sendPhoneNumberVerification = () => {
    refetch();
  };

  const phoneNumberIsValid = () => isPhoneNumberValid(mobileNumber);

  const isFetching = isLoading || isRefetching;

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (phoneNumberIsValid() && event.key === 'Enter') {
      sendPhoneNumberVerification();
    }
  };

  return (
    <Formik
      initialValues={{
        mobileNumber
      }}
      onSubmit={sendPhoneNumberVerification}
    >
      {() => (
        <Form>
          <div className='flex flex-col gap-3'>
            {!hideText && (
              <p>
                <Trans>Enter number to receive a verification code</Trans>
              </p>
            )}
            <NoddiPhoneInput onChange={setMobileNumber} value={mobileNumber} onKeyDown={onKeyDown} />
            {isSuccess ? (
              <div className='flex items-center justify-between'>
                <p>
                  <Trans>Didn't receive sms?</Trans>
                </p>
                <div>
                  <NoddiButton
                    type='submit'
                    disabled={!phoneNumberIsValid() || isFetching}
                    loading={isLoading || isFetching}
                    variant='link'
                    size='small'
                  >
                    {t`Resend`}
                  </NoddiButton>
                </div>
              </div>
            ) : (
              <div className='flex justify-end gap-4'>
                {error && (
                  <div className='flex justify-center'>
                    <ApiErrorMessage error={error} />
                  </div>
                )}
                {callBackObject && (
                  <NoddiButton
                    onClick={() => {
                      callBackObject.customCallback();
                    }}
                    variant='secondary'
                  >
                    {callBackObject.title}
                  </NoddiButton>
                )}

                <NoddiButton type='submit' disabled={!phoneNumberIsValid()} loading={isLoading}>
                  <Trans>Send code</Trans>
                </NoddiButton>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { PhoneNumberForm };
