import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { URLKeys, noddiAsync } from 'noddi-async';
import { CarListItem, TireHotelOptionsResponse } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import {
  ErrorPage,
  KeyValueRow,
  LoadingScreen,
  NoddiCard,
  NoddiContainer,
  TypographyWithInfoSign,
  colors,
  getCarDisplayName
} from 'noddi-ui';
import { DateFormats, format } from 'noddi-util';
import { useParams } from 'react-router-dom';

import routes from '../../../appRoutes';
import { ActionOptionDisabled, actions } from '../BookingFlow/Components/actions';
import TireHotelHeaderNode from '../TireHotelHeaderNode';

const TireHotelDetailContent = () => {
  const { id } = useParams();

  const { currentUserGroupId } = useAuthContext();

  const {
    data: tireHotelContracts,
    isPending,
    error: tireHotelContractsError
  } = noddiAsync.useGet({
    type: URLKeys.getTireHotelContracts,
    input: { userGroupId: currentUserGroupId as number },
    queryConfig: {
      enabled: !!currentUserGroupId,
      staleTime: Infinity,
      select: (data) => {
        return data.filter((contract) => contract.id === Number(id));
      }
    }
  });

  const vinNumbers =
    tireHotelContracts?.map((contract) => contract.car.vinNumber).filter((x): x is string => Boolean(x)) ?? [];

  const {
    data: tireHotelOptions,
    isPending: optionsIsPending,
    error: optionsError
  } = noddiAsync.useGet({
    type: URLKeys.getTireHotelOptions,
    input: { vinNumbers, userGroupId: currentUserGroupId },
    queryConfig: {
      enabled: vinNumbers.length > 0,
      staleTime: Infinity
    }
  });

  if (isPending || optionsIsPending) {
    return <LoadingScreen />;
  }
  if (!currentUserGroupId) {
    return <ErrorPage />;
  }
  if (tireHotelContractsError || optionsError) {
    return <ErrorPage apiError={[tireHotelContractsError, optionsError]} />;
  }

  const tireHotelContract = tireHotelContracts[0];

  if (!tireHotelContract) {
    return <ErrorPage />;
  }
  const { car, bookingItemSalesItem } = tireHotelContract;
  const options = getOptions(tireHotelOptions, car);
  // unique
  const disabledOptions = options
    .filter((option) => option.disabled)
    .map((option) => option.disabledReason)
    .filter((value, index, self) => self.indexOf(value) === index);

  const disabledTexts = getDisabledTexts();

  return (
    <>
      <NoddiContainer
        breadcrumbProps={{
          links: [
            {
              title: t`Tire hotel overview
`,
              path: routes.tireStorage.getPath()
            },
            {
              title: getCarDisplayName(car),
              path: routes.tireStorageDetail.getPath({ id: tireHotelContract.id })
            }
          ]
        }}
        header={t`Tire hotel details`}
        description={t`Manage your tire hotel contract`}
        headerNode={
          <TireHotelHeaderNode
            buttonText={t`Manage contract`}
            bookingActions={options}
            tireHotelContracts={tireHotelContracts}
            tireHotelOptions={tireHotelOptions}
          />
        }
      >
        <NoddiCard cardContentSx={{ maxWidth: '600px' }}>
          <Typography marginBottom={3} variant='h5'>
            {getCarDisplayName(car)}
          </Typography>
          {tireHotelContract.startedAt ? (
            <KeyValueRow header={t`Started at`} value={format(tireHotelContract.startedAt, DateFormats.DOTTED_DATE)} />
          ) : (
            <KeyValueRow
              header={t`Planned start`}
              value={format(tireHotelContract.startsAt, DateFormats.DOTTED_DATE)}
            />
          )}
          {bookingItemSalesItem && (
            <KeyValueRow
              header={t`Price`}
              value={`${bookingItemSalesItem.salesItem.price} ${bookingItemSalesItem.salesItem.currency} `}
              showDivider={false}
            />
          )}

          {disabledOptions.length > 0 && (
            <Box sx={{ backgroundColor: colors.primary.darkPurple30 }} padding={3} borderRadius={2}>
              {disabledOptions.map((option) => (
                <TypographyWithInfoSign key={option}>
                  {disabledTexts[option as keyof typeof disabledTexts]}
                </TypographyWithInfoSign>
              ))}
            </Box>
          )}
        </NoddiCard>
      </NoddiContainer>
    </>
  );
};

export default TireHotelDetailContent;

function getOptions(tireHotelOptions: TireHotelOptionsResponse[], car: CarListItem): ActionOptionDisabled[] {
  const carOption = tireHotelOptions.find((option) => option.vinNumber === car.vinNumber);

  const options = [
    {
      value: actions.order,
      label: t`Order tire change`
    },
    {
      value: actions.cancel,
      label: t`End tire hotel agreement`
    }
  ];

  if (!carOption) {
    return options;
  }

  const canRenewReason = !carOption.tireHotel.canRenewTireHotel && carOption.tireHotel.canRenewReason;
  const canCancelReason = !carOption.tireHotel.canTerminateTireHotel && carOption.tireHotel.canTerminateReason;

  return options.map((option) => {
    if (option.value === actions.order) {
      return { ...option, disabled: !!canRenewReason, disabledReason: canRenewReason || null };
    }
    return { ...option, disabled: !!canCancelReason, disabledReason: canCancelReason || null };
  });
}

function getDisabledTexts() {
  return {
    no_active_contract: t`You have an upcoming tire hotel booking for this vehicle. It is therefore not possible to order another one`,
    has_upcoming_contract: t`You have an upcoming tire hotel booking for this vehicle. It's not possible to order another one`,
    upcoming_renewal: t`There is an upcoming renewal for this vehicle. It is therefore not possible to order another one`,
    upcoming_termination: t`There is an upcoming termination for this vehicle. It is therefore not possible to order another one`
  } as const;
}
