import { ServerTypes } from 'noddi-async';
import { MembershipDiscountProps } from 'noddi-async/src/types';
import { roundNumber } from 'noddi-util';

import { SelectedSalesItem } from '../pages/BookingFlow/interfaces';
import { ActiveCoupon } from '../stores/CouponStore';

type DiscountPriceArgs = {
  discounts: MembershipDiscountProps[];
  salesItem: SelectedSalesItem;
  coupons: ActiveCoupon[];
};

/**
 * Finds the discount for a given sales item
 * @param param0
 * @returns the price for a given sales item with the discount applied
 */
export const getDiscountedPrice = ({ discounts, salesItem, coupons }: DiscountPriceArgs) => {
  const discount = discounts.find((discount) => discount.salesItemIds.includes(salesItem.id));
  let couponDiscounts = 0;

  for (const coupon of coupons) {
    if (coupon?.amount) {
      couponDiscounts += coupon?.amount;
    } else if (coupon?.amountPercentage) {
      couponDiscounts += (coupon?.amountPercentage / 100) * salesItem.price;
    }
  }

  let memberShipDiscount = 0;
  if (discount?.price) {
    memberShipDiscount = discount?.price;
  }

  const percentage = discount ? 100 - Number(discount?.percentage) : 100;

  return (
    roundNumber({ number: (salesItem.price * percentage) / 100, decimalPlaces: 2 }) -
    couponDiscounts -
    memberShipDiscount
  );
};

/**
 * Used for calculating the discounted amount for a given sales item
 *
 * @param discounts all discounts that may apply from memberships
 * @param salesItem the sales item in question
 * @returns the discounted amount for a given sales item
 */
export const getMembershipDiscountAmount = (
  discounts: MembershipDiscountProps[],
  salesItem: ServerTypes.AvailableSalesItem
) => {
  const discount = discounts.find((discount) => discount.salesItemIds.includes(salesItem.id));
  if (!discount) {
    return 0;
  }
  return roundNumber({ number: (salesItem.price * Number(discount.percentage)) / 100, decimalPlaces: 2 });
};

/**
 * Used for calculating the discounted amount for a given coupon
 * @param coupon the coupon in question
 * @param salesItemPrice the price of the sales item
 * @returns the discounted amount for a given coupon
 *
 */
export const getCouponDiscountAmount = (coupon: ActiveCoupon | null | undefined, salesItemPrice: number) => {
  let couponDiscount = 0;
  if (coupon?.amount) {
    couponDiscount = coupon?.amount;
  } else if (coupon?.amountPercentage) {
    couponDiscount = roundNumber({ number: (coupon?.amountPercentage / 100) * salesItemPrice, decimalPlaces: 2 });
  }
  return couponDiscount;
};

type DiscountAmountArgs = {
  discounts: MembershipDiscountProps[];
  salesItem: ServerTypes.AvailableSalesItem;
  coupon?: ActiveCoupon | null;
};
export const getDiscountAmount = ({ discounts, salesItem, coupon }: DiscountAmountArgs) => {
  const membershipDiscount = getMembershipDiscountAmount(discounts, salesItem);
  const couponDiscount = getCouponDiscountAmount(coupon, salesItem.price);
  return membershipDiscount + couponDiscount;
};
