import { t } from '@lingui/macro';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { ErrorPage, LoadingScreen, NoddiContainer, StickyBottomDiv, useIsMobile } from 'noddi-ui';

import ConfirmStorageChangeButton from '../../ConfirmStorageChangeButton';
import ContractList from '../../ContractList';
import { useTireHotelContext } from '../context';

const stickyBottomDivHeight = '20vh';

const TireHotelPageContent = () => {
  const { currentUserGroupId } = useAuthContext();
  const { selectedAction, dialogOpen } = useTireHotelContext();

  const {
    data: tireHotelContracts,
    isPending,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getTireHotelContracts,
    input: { userGroupId: currentUserGroupId as number, isActive: true },
    queryConfig: {
      enabled: !!currentUserGroupId,
      staleTime: Infinity
    }
  });

  const vinNumbers =
    tireHotelContracts?.map((contract) => contract.car.vinNumber).filter((x): x is string => Boolean(x)) ?? [];

  const {
    data: tireHotelOptions,
    isLoading: optionsIsLoading,
    error: optionsError
  } = noddiAsync.useGet({
    type: URLKeys.getTireHotelOptions,
    input: { vinNumbers, userGroupId: currentUserGroupId },
    queryConfig: {
      enabled: vinNumbers.length > 0,
      staleTime: Infinity
    }
  });

  const isMobile = useIsMobile();

  if (isPending) {
    return <LoadingScreen />;
  }
  if (optionsIsLoading) {
    return <LoadingScreen />;
  }
  if (!currentUserGroupId) {
    return <ErrorPage />;
  }
  if (error || optionsError) {
    return <ErrorPage apiError={[error, optionsError]} />;
  }

  const showConfirmElement = selectedAction && tireHotelContracts.length > 1;

  return (
    <NoddiContainer
      header={t`Tire hotel overview`}
      description={t`List of your tire hotel contracts. Click on a contract to view details and manage.`}
      contentContainerProps={{
        sx: { paddingBottom: showConfirmElement && isMobile ? stickyBottomDivHeight : 'none' }
      }}
    >
      {tireHotelOptions && <ContractList tireHotelContracts={tireHotelContracts} tireHotelOptions={tireHotelOptions} />}

      {showConfirmElement &&
        (isMobile ? (
          !dialogOpen && (
            <StickyBottomDiv height={stickyBottomDivHeight}>
              <Stack direction='column' justifyContent='center' alignItems='center' height='100%' paddingX={2} gap={2}>
                <ConfirmStorageChangeButton />
              </Stack>
            </StickyBottomDiv>
          )
        ) : (
          <Box marginTop='1rem' width='fit-content'>
            <ConfirmStorageChangeButton />
          </Box>
        ))}
    </NoddiContainer>
  );
};

export default TireHotelPageContent;
