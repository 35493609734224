import { t } from '@lingui/macro';
import { SMSLogin, UserRegistrationForm, useLoginRedirect } from 'noddi-ui';

import routes from '../../../../appRoutes';
import BookingScreen from '../../BookingScreen';

export const LoginBookingFlow = () => {
  useLoginRedirect({ homePath: routes.newBookingSummaryStep.getPath() });

  return (
    <BookingScreen
      title={t`What is your number?`}
      description={t`We need your number for keeping you informed about your order via SMS. Please enter your phone number to receive a verification code.`}
      hideNextButton
      hideShoppingCart
    >
      <SMSLogin
        userRegistrationForm={(phoneNumber) => <UserRegistrationForm phoneNumber={phoneNumber} skipPasswordCreation />}
        hidePhoneNumberText
      />
    </BookingScreen>
  );
};
