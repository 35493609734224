/* eslint-disable no-console */
import { captureException } from '@sentry/react';
import ExternalMixpanel, { Dict } from 'mixpanel-browser';
import { UserData } from 'noddi-async/src/types';

//eslint-disable-next-line import/no-named-as-default-member
const { track, identify, register, ...rest } = ExternalMixpanel;

function assertNonNullish<TValue>(value: TValue, message: string): asserts value is NonNullable<TValue> {
  if (value === null || value === undefined) {
    captureException(message);
  }
}

const NoddiMixpanel: typeof ExternalMixpanel = {
  track: (eventName: string, extra?: Dict, callback?: () => void) => {
    console.log(eventName, extra);
    if (callback) {
      callback();
    }
  },
  identify: console.log,
  register: (extra: Dict) => console.log('register ', extra),

  ...rest,
  people: {
    ...rest.people,
    set: (extra) => console.log('set ', extra)
  },
  alias: (id: string, originalId?: string) => console.log('alias ', { id, originalId })
};

const isProd = import.meta.env.MODE === 'production';

const mixpanel = isProd ? ExternalMixpanel : NoddiMixpanel;

export const commonTrackingEvents = {
  bookingCreated: 'booking created',
  unavailableAddressArea: 'unavailable address area',
  userSignedUp: 'userSignedUp',
  bookingUpdated: 'booking updated'
};

class Tracking {
  initMixpanel() {
    const prodKey = import.meta.env.VITE_APP_MIXPANEL_PROJECT_TOKEN;

    if (isProd) {
      assertNonNullish(
        prodKey,
        `prodKey does not have a value, ${import.meta.env.VITE_APP_MIXPANEL_PROJECT_TOKEN} is not defined`
      );
    }

    mixpanel.init(isProd ? (prodKey ?? '') : '', {
      debug: isProd,
      persistence: 'localStorage'
    });
  }

  track(eventName: string, extra?: Dict, callback?: () => void) {
    mixpanel.track(eventName, extra, callback);
  }

  trackUserIdentity(user: UserData) {
    this.identify(user);
    this.track(commonTrackingEvents.userSignedUp);
  }

  identify(user: UserData) {
    mixpanel.identify(user.id.toString());
    mixpanel.people.set('userId', user.id);
    mixpanel.people.set('email', user.email);
    mixpanel.people.set('phone_number', user.phoneNumber);
    mixpanel.people.set('is_worker', Boolean(user.isWorker));
  }

  alias(id: string) {
    mixpanel.alias(id);
  }

  // people property
  setPeopleProperty(name: string, value: string | number | boolean) {
    mixpanel.people.set({ [name]: value });
  }

  // super property
  register(extra: Dict) {
    mixpanel.register(extra);
  }
}

const tracking = new Tracking();

export default tracking;
